<template>
	<form class="login-form">
		<div class="login-form__header">
			Регистрация
		</div>
		<div class="mb--large">
			<div class="login-form__fio">
				<div class="login-form__input">
					<b-input
						block
						:error="error"
						label="Имя"
						v-model="fields.name"
						@input="() => error = false"
						class="input__fio input__login"
					/>
				</div>
				<div class="login-form__input">
					<b-input
						block
						:error="error"
						label="Фамилия"
						v-model="fields.lastname"
						@input="() => error = false"
						class="input__fio input__password"
					/>
				</div>
			</div>
			<div class="login-form__input-info">
				Имя и фамилия должны быть такими же, как и в вашем профиле Slack
			</div>
		</div>
		<div class="login-form__input mb">
			<b-input
				block
				:error="error"
				label="Логин"
				v-model="fields.login"
				@input="() => error = false"
			/>
		</div>
		<div class="login-form__input">
			<b-input
				block
				:error="error"
				label="Электронная почта"
				type="email"
				v-model="fields.email"
				@input="() => error = false"
			/>
		</div>
		<div v-if="!error" class="login-form__input-info mb--large">
			Почта должна совпадать с данными в Slack
		</div>
		
		<div class="login-form__password-container">
			<div class="login-form__input">
				<b-input
					block
					:error="error"
					password
					label="Пароль"
					v-model="fields.password"
					@input="() => error = false"
				/>
			</div>
			<div class="login-form__input">
				<b-input
					block
					:error="error"
					password
					label="Повтор пароля"
					v-model="password2"
					@input="() => error = false"
				/>
			</div>
		</div>
		<div v-if="error" class="login-form__info--error">
			{{errorMessage}}
		</div>
		<div class="login-form__submit">
			<b-button
				large
				block
				:error="error"
				@click.prevent="doRegister"
			>
				Зарегистрироваться
			</b-button>
		</div>
		<div v-if="!error" class="login-form__info">
			или <router-link :to="{name: 'Root'}">войдите</router-link> в уже существующий аккаунт
		</div>
	</form>
</template>

<script>
import input from "@/components/basic/input.vue";
import button from "@/components/basic/button.vue";

import api from "@/api"


export default {
	name: "b-form",
	components: {
		"b-input": input,
		"b-button": button,
	},
	data: () => ({
		fields: {
			name: '',
			login: '',
			lastname: '',
			email: '',
			password: ''
		},
		password2: '',
		loading: false,
		error: false,
		errorMessage: ""
	}),
	methods: {
		doRegister(){
			for (const key in this.fields) {
				const item = this.fields[key]
				if(item.length == 0){
					this.error = true
					this.errorMessage = "Все поля обязательны для заполнения"
					return
				}
			}

			if(this.fields.name.length < 4){
				this.error = true
				this.errorMessage = "Имя слишком короткое"
				return
			}

			if(this.fields.login.length < 4){
				this.error = true
				this.errorMessage = "Логин слишком короткий"
				return
			}

			let regexEmail = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(.\w{2,3})+$/;
			if(!this.fields.email.match(regexEmail)){
				this.error = true
				this.errorMessage = "Неверный email"
				return
			}

			if(this.fields.password != this.password2){
				this.error = true
				this.errorMessage = "Пароли не совпадают"
				return
			}
			
			const send = {
				name: this.fields.name + ' ' + this.fields.lastname,
				login: this.fields.login,
				email: this.fields.email,
				password: this.fields.password
			}

			this.$store.dispatch('auth/generateDevice_id', {login: this.fields.login, password: this.fields.password})
			api.registration(send)
			.then((res) => {
				if(res.message){
					throw res
				}
				this.$router.push({name: 'Root', query: {state:"3"}});
			})
			.catch((err) => {
				this.error = true;
				if(err.message){
					this.errorMessage = err.message
				}
			})
		}
	}
}
</script>

<style scoped lang="sass">
.login-form
	&__header
		font-size: 28px
		font-weight: 600
		line-height: 36px
		margin-bottom: 16px

	&__submit
		margin-bottom: 24px
		margin-top: 32px
	
	&__info
		font-size: 14px
		line-height: 20px
		color: $secondary-dark

		&--error
			margin-top: -8px
			color: $error
			margin-bottom: 24px
			.info__accent
				color: $error

	&__input-info
		margin-top: 12px
		color: $gray-dark-3
		font-size: 14px
		line-height: 140%

	&__password-container
		display: flex
		flex-direction: column
		grid-gap: 20px

	&__fio
		display: flex
		flex-direction: column
		border-radius: 10px
		box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08)
		border: 1px solid #9F9F9F

.mb
	margin-bottom: 20px
	&--large
		margin-bottom: 32px

.info__accent
	color: $main-dark
	text-decoration: underline
	cursor: pointer
		
.input
	&__fio
		box-shadow: none
	&__login
		border: none
		border-bottom: 1px solid rgba(159, 159, 159, 0.3)
		border-radius: 10px 10px 0 0
	&__password
		border: none
		border-top: 1px solid rgba(159, 159, 159, 0.3)
		border-radius: 0 0 10px 10px


@media screen and (min-width: $md)
	.login-form
		&__password-container
			flex-direction: row
			grid-gap: 14px
		&__fio
			flex-direction: row

		&__input-info
			margin-top: 8px
			
	.mb
		&--large
			margin-bottom: 24px
		
	.input
		&__login
			border-bottom: none
			border-right: 1px solid rgba(159, 159, 159, 0.3)
			border-radius: 10px 0 0 10px
		&__password
			border-top: none
			border-left: 1px solid rgba(159, 159, 159, 0.3)
			border-radius: 0 10px 10px 0
		
</style>