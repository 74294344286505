<template>
	<div class="login__view">
		<b-header/>
		<main class="login__main">
			<div class="container">
				<b-form/>
			</div>
		</main>
		<b-footer login-page no-tutorial/>
	</div>
</template>

<script>
import header from '@/components/header.vue'
import form from '@/components/registerForm.vue'
import footer from '@/components/footer.vue'
export default {
	name: "login-view",
	components: {
		"b-header": header,
		"b-form": form,
		"b-footer": footer
	},
	mounted(){
		const img = new Image();
		img.src = '/img/tutorial/1.webp'
	}
}
</script>

<style scoped lang="sass">
.login
	&__view
		height: 100%
		display: flex
		flex-direction: column
	&__main
		flex: 1 0 auto
		display: flex
		justify-content: center
		align-items: center

.container
	width: 100%
	padding: 0 24px

@media screen and (min-width: $xs)
	.container
		width: 340px

@media screen and (min-width: $sm)
	.container
		width: 360px
		padding: 0

@media screen and (min-width: $md)
	.container
		width: 410px

</style>